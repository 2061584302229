html, body,#root {
    height: 100%;
    
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  
  body > * {
    flex-shrink: 0;
  }

  
.fixed-container {
    position: fixed;
    top: 65px;
    left: 0;
    width: 100%;
}
